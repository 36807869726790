@use './libs/core/src/lib/styles/themes';

.labels-selector-view {
  @include themes.themify() {
    padding-top: themes.themed('spacing', 'L');
  }
  font-size: 13px;
  .labels-title {
    font-size: 18px;
  }
  .labels-selector-container-name {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px
  }
}
