@use '../themes' as *;

@mixin badge-theme-props($badge-type) {
  @include themify() {
    background-color: themed($badge-type, 'background-color');
    color: themed($badge-type, 'color');
    padding: themed($badge-type, 'padding');
    font-size: themed($badge-type, 'font-size');
    margin: themed($badge-type, 'margin');
  }
}

@mixin badge-primary-theme-props() {
  @include badge-theme-props('badge-primary');
}

@mixin badge-secondary-theme-props() {
  @include badge-theme-props('badge-secondary');
}
