@use 'libs/core/src/lib/styles/themes';

.organization-section-banner {
  @include themes.themify {
    display: flex;
    align-items: center;
    padding-top: themes.themed('spacing', M);
    width: 100%;

    &.collapsed {
      padding-bottom: themes.themed('spacing', M);
    }
  }
}
