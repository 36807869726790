@use 'libs/core/src/lib/styles/themes';

.brandfolder-list-item {
  @include themes.themify {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    padding-top: themes.themed('spacing', L);
    padding-bottom: themes.themed('spacing', L);

    &:last-of-type {
      padding-bottom: themes.themed('spacing', S);
    }
    .collections-toggle-button {
      padding: themes.themed('spacing', XS);
    }

    .container-thumbnail {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex: 0 0 80px;
      min-height: 60px;
      height: 100%;
      margin-right: themes.themed('spacing', L);

      img {
        width: 80px;
        height: 60px;
        object-fit: contain;
      }
    }
    .container-name {
      padding: 0 themes.themed('spacing', XS);
    }
    .container-asset-count {
      padding: 0 themes.themed('spacing', XS);
    }
  }
}
