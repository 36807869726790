@use 'libs/core/src/lib/styles/themes';

.spacer-container {
  @include themes.themify {
    display: flex;

    &.vertical {
      flex-direction: column;
    }

    &.xxs {
      gap: themes.themed('spacing', 'XXS');
    }

    &.xs {
      gap: themes.themed('spacing', 'XS');
    }

    &.s {
      gap: themes.themed('spacing', 'S');
    }

    &.m {
      gap: themes.themed('spacing', 'M');
    }

    &.l {
      gap: themes.themed('spacing', 'L');
    }

    &.xl {
      gap: themes.themed('spacing', 'XL');
    }

    &.xxl {
      gap: themes.themed('spacing', 'XXL');
    }

    .grow {
      flex: 1 1 100%;
    }
  }
}
