@use '../themes' as *;

@mixin btn-theme-props($button-type) {
  @include themify() {
    background-color: themed($button-type, 'background-color');
    border: themed($button-type, 'border');
    border-radius: themed($button-type, 'border-radius');
    color: themed($button-type, 'color');
    font-family: themed($button-type, 'font-family');
    font-weight: themed($button-type, 'font-weight');
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition: background-color 0.3s ease, border-color 0.3s ease, width 0.5s ease;
    padding: themed($button-type, 'padding');

    .btn-content {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      overflow-x: hidden;
      gap: 6px;
    }

    &.dynamic {
      .btn-content {
        justify-content: flex-start;
      }
    }

    &.has-icons {
      padding: themed($button-type, 'padding-with-icons');

      .btn-content {
        gap: themed('spacing', 'S');
      }
    }

    &.has-after-icon {
      .btn-content {
        justify-content: space-between;
      }
    }

    &.btn-primary {
      .btn-content, .smar-spinner {
        color: themed($button-type, 'color');
      }
    }

    &.disabled,
    &.disabled:hover {
      background-color: themed($button-type, 'background-color-disabled');
      border: themed($button-type, 'border-disabled');
      color: themed($button-type, 'color-disabled');
    }

    &:active {
      background-color: themed($button-type, 'background-color-active');
    }

    &:hover {
      background-color: themed($button-type, 'background-color-hover');
      border: themed($button-type, 'border-hover');
    }
  }
}

@mixin btn-primary-theme-props() {
  @include btn-theme-props('button-primary');
}

@mixin btn-secondary-theme-props() {
  @include btn-theme-props('button-secondary');
}

@mixin btn-neutral-theme-props() {
  @include btn-theme-props('button-neutral');
}

@mixin btn-active-dark-theme-props() {
  @include btn-theme-props('button-active-dark');
}

@mixin btn-disabled-theme-props() {
  @include btn-theme-props('button-disabled');
}

@mixin btn-success-theme-props() {
  @include btn-theme-props('button-success');
}
