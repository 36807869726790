@use '../themes' as *;

@mixin icon-base-theme-props() {
  min-width: 100%;
  min-height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin icon-arrow-right-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-arrow-right', 'background-image') no-repeat center;
    background-size: themed('icon-arrow-right', 'background-size');
  }
}

@mixin icon-arrow-right-white-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-arrow-right-white', 'background-image') no-repeat center;
    background-size: themed('icon-arrow-right-white', 'background-size');
  }
}

@mixin icon-caret-down-primary-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-caret-down-primary', 'background-image') no-repeat center;
    background-size: themed('icon-caret-down-primary', 'background-size');
  }
}

@mixin icon-caret-down-secondary-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-caret-down-secondary', 'background-image') no-repeat center;
    background-size: themed('icon-caret-down-secondary', 'background-size');
  }
}

@mixin icon-caret-down-white-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-caret-down-white', 'background-image') no-repeat center;
    background-size: themed('icon-caret-down-white', 'background-size');
  }
}

@mixin icon-caret-right-primary-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-caret-right-primary', 'background-image') no-repeat center;
    background-size: themed('icon-caret-right-primary', 'background-size');
  }
}

@mixin icon-caret-left-secondary-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-caret-left-secondary', 'background-image') no-repeat center;
    background-size: themed('icon-caret-left-secondary', 'background-size');
  }
}

@mixin icon-caret-left-primary-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-caret-left-primary', 'background-image') no-repeat center;
    background-size: themed('icon-caret-left-primary', 'background-size');
  }
}

@mixin icon-caret-right-secondary-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-caret-right-secondary', 'background-image') no-repeat center;
    background-size: themed('icon-caret-right-secondary', 'background-size');
  }
}

@mixin icon-check-filled-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-check-filled', 'background-image') no-repeat center;
    background-size: themed('icon-check-filled', 'background-size');
  }
}

@mixin icon-chevron-down-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-chevron-down', 'background-image') no-repeat center;
    background-size: themed('icon-chevron-down', 'background-size');
  }
}

@mixin icon-chevron-right-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-chevron-right', 'background-image') no-repeat center;
    background-size: themed('icon-chevron-right', 'background-size');
  }
}

@mixin icon-close-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-close', 'background-image') no-repeat center;
    background-size: themed('icon-close', 'background-size');
  }
}

@mixin icon-close-thin-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-close-thin', 'background-image') no-repeat center;
    background-size: themed('icon-close-thin', 'background-size');
  }
}

@mixin icon-download-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-download', 'background-image') no-repeat center;
    background-size: themed('icon-download', 'background-size');
  }
}

@mixin icon-drag-and-drop-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-drag-and-drop', 'background-image') no-repeat center;
    background-size: themed('icon-drag-and-drop', 'background-size');
  }
}

@mixin icon-filters-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-filters', 'background-image') no-repeat center;
    background-size: themed('icon-filters', 'background-size');
  }
}

@mixin icon-filters-active-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-filters-active', 'background-image') no-repeat center;
    background-size: themed('icon-filters-active', 'background-size');
  }
}

@mixin icon-more-options-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-more-options', 'background-image') no-repeat center;
    background-size: themed('icon-more-options', 'background-size');
  }
}

@mixin icon-open-in-brandfolder-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-open-in-brandfolder', 'background-image') no-repeat center;
    background-size: themed('icon-open-in-brandfolder', 'background-size');
  }
}

@mixin icon-pin-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-pin', 'background-image') no-repeat center;
    background-size: themed('icon-pin', 'background-size');
  }
}

@mixin icon-pin-active-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-pin-active', 'background-image') no-repeat center;
    background-size: themed('icon-pin-active', 'background-size');
  }
}

@mixin icon-search-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-search', 'background-image') no-repeat center;
    background-size: themed('icon-search', 'background-size');
  }
}

@mixin icon-arrow-caret-left-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-arrow-caret-left', 'background-image') no-repeat center;
    background-size: themed('icon-arrow-caret-left', 'background-size');
  }
}

@mixin icon-check-mark-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-check-mark', 'background-image') no-repeat center;
    background-size: themed('icon-check-mark', 'background-size');
  }
}

@mixin icon-labels-check-mark-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-labels-check-mark', 'background-image') no-repeat center;
    background-size: themed('icon-labels-check-mark', 'background-size');
  }
}

@mixin icon-alert-filled-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-alert-filled', 'background-image') no-repeat center;
    background-size: themed('icon-alert-filled', 'background-size');
  }
}

@mixin icon-info-filled-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-info-filled', 'background-image') no-repeat center;
    background-size: themed('icon-info-filled', 'background-size');
  }
}

@mixin icon-content-theme-props() {
  @include icon-base-theme-props();
  @include themify() {
    background: themed('icon-content', 'background-image') no-repeat center;
    background-size: themed('icon-content', 'background-size');
  }
}
