@use 'libs/core/src/lib/styles/components/icons';
@use 'libs/core/src/lib/styles/themes';

.icon-paperclip {
  @include themes.themify {
    @include icons.icon-base-theme-props();
    background-image: themes.themed('icon-paperclip', 'background-image');

    &.white {
      filter: themes.themed('colors', 'white-filter');
    }
  }
}
