@use './libs/core/src/lib/styles/themes';

.notifications-container {
  position: absolute;
  width: 99%;
  z-index: 999;
  height: 0;
  pointer-events: none;
  .Toastify__toast-container {
    --toastify-toast-min-height: 48px;
    pointer-events: auto;
    width: 100%;
    position: inherit;
    .Toastify__toast-icon {
      svg {
        fill: #111111 !important;
      }
    }
    .toast-success {
      @include themes.themify() {
        background: themes.themed('colors', 'notification-success');
      }
      color: black;
    }
    .toast-error {
      @include themes.themify() {
        background: themes.themed('colors', 'notification-error');
      }
      color: white;
    }
    .Toastify__toast {
    }
    .Toastify__toast-body > div:last-child {
      color: #111111;
      flex: 1;
      font-size: 14px;
      font-weight: 500;
    }
    .Toastify__toast {
      transition: opacity 0.3s ease-in-out;
      background: #bbe7d6;
      min-height: var(--toastify-toast-min-height) !important;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      @include themes.themify() {
        box-shadow: themes.themed('colors', 'box-shadow');
      }
      .notification-message {
        padding-left: 10px;
        font-weight: 500;
      }
      &.open {
        opacity: 1;
      }
      .Toastify__progress-bar {
        visibility: hidden;
      }
      .Toastify__close-button {
        margin: auto;
      }

      .Toastify__close-button svg path {
        fill: #111111 !important;
        d: path('M 1 1.99512 L 13 13.9951 M 13 1.99512 L 1 13.9951') !important;
        stroke-width: 2 !important;
        stroke-linecap: round !important;
        stroke: #111111 !important;
      }

      .Toastify__close-button--light {
        opacity: 1 !important;
      }
    }
  }
}
