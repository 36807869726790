@use 'libs/core/src/lib/styles/components/icons';
@use 'libs/core/src/lib/styles/themes';

.icon-success-checkmark {
  @include themes.themify {
    @include icons.icon-base-theme-props();
    background: url(assets/success.svg) no-repeat center;
    filter: themes.themed('colors', 'success-filter');
  }
}
