@use './libs/core/src/lib/styles/themes';

.menu-card-popover {
  background-color: white;
  border-radius: 4px;
  height: fit-content;
  padding: 8px;
  width: fit-content;

  @include themes.themify() {
    box-shadow: themes.themed('colors', 'box-shadow');
  }
}

.menu-card-item {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 8px;

  &:hover {
    @include themes.themify() {
      background-color: themes.themed('background-hover', 'background-color');
      border-radius: themes.themed('background-hover', 'border-radius');
      transition: background-color 0.3s ease;
    }
  }
}

.actions-overlay-button {

  > button {
    padding-right: 6px !important;
  }

  .divider {
    border-left: 1px solid #ffffff;
    display: block;
    height: 100%;
    margin-left: 12px;
    width: 1px;
  }

  .icon-caret-down-white {
    margin-left: 6px;
    min-width: 10px !important;
    width: auto !important;
  }
}

.actions-overlay-menu {
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 2px 23px 5px rgba(203, 203, 203, 0.25);
  display: flex;

  &__item {
    &:hover {
      @include themes.themify() {
        background-color: themes.themed('background-hover', 'background-color');
        border-radius: themes.themed('background-hover', 'border-radius');
        transition: background-color 0.3s ease;
      }
    }
  }
}
