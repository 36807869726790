@use 'libs/core/src/lib/styles/themes' as *;

.bf-select {
  &.s {
    height: 40px;
  }

  &.m {
    height: 50px;
  }
}

.bf-select-placeholder {
  color: #959bac;
}

.select,
.multi-select-button {
  /* style Select with custom background caret image */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-repeat: no-repeat;
  background-position-x: 84%;
  background-position-y: 50%;
  border-radius: 4px;

  @include themify() {
    &:hover {
      border-color: themed('select', 'border-hover');
      cursor: pointer;
      transition: border-color 0.3s ease;
    }
  }

  @include themify() {
    background-color: themed('select', 'background-color');
    background-image: themed('select', 'background-image');
    border-color: themed('select', 'border-color');

    .option:hover {
      background-color: themed('select', 'background-hover');
      border-radius: themed('select', 'border-radius-hover');
      transition: background-color 0.3s ease;
    }
  }
}

.lodestar--focus-using-before {
  [type='text']:focus {
    box-shadow: none !important;
  }
}

.multi-select-placeholder {
  @include themify {
    font-family: themed('select', 'font-family');
    font-style: normal;
    font-weight: themed('select', 'placeholder-font-weight');
    font-size: themed('select', 'font-size');
    line-height: themed('select', 'line-height');
    color: themed('select', 'placeholder-color');
  }
}

.multi-select-option {
  @include themify {
    font-family: themed('select', 'font-family');
    font-style: normal;
    font-weight: themed('select', 'font-weight');
    font-size: themed('select', 'font-size');
    line-height: themed('select', 'line-height');
    color: themed('select', 'color');
  }
}

.multi-select-button,
.multi-select-dropdown {
  font-size: 14px;
  line-height: 23px;
  width: 100%;
  max-width: 400px;
  margin: 12px 0;
  padding: 0.5rem;
  border-radius: 4px;
  border-color: rgba(206, 206, 206, 1);
  border-width: 1px;
  background: white;
  cursor: pointer;
}

.multi-select-button {
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  min-height: 40px;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &.disabled {
    opacity: 0.7;
    color: #9b9b9b;
    background-color: #f5f5f5;
    &:hover {
      border-color: rgba(206, 206, 206, 1);
      cursor: default;
      transition: border-color 0.3s ease;
    }
  }
  .multi-select-selected-item {
    background: #f3f5fa;
    padding: 0px 3px;
    border-radius: 4px;
    margin-right: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      opacity: 0.8;
    }
  }
}

.multi-select-dropdown {
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 100;
  position: absolute;
  max-height: 400px;
  overflow: scroll;
  .multi-select-item {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
}
