@use 'libs/core/src/lib/styles/themes';

.asset-thumbnail-content {
  position: relative;

  &:hover {
    .asset-overlay {
      opacity: 1;
    }
  }
  .asset-overlay.show {
    opacity: 1;
  }
}

.assets-list-by-section {
  @include themes.themify {
    &:last-child {
      border-bottom: none;
    }

    .bf-hr {
      margin-top: themes.themed('spacing', XL);
      padding-bottom: themes.themed('spacing', M);
    }
  }
}
