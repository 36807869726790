@use './libs/core/src/lib/styles/themes';

.page-loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  &.full {
    height: 100vh;
  }

  @include themes.themify() {
    $header-height: themes.themed('page-loader', 'header-height');
    $org-header-height: themes.themed('page-loader', 'org-header-height');
    $min-height: themes.themed('page-loader', 'min-height');

    &.full-minus-header {
      min-height: calc(#{$min-height} - #{$header-height});
    }

    &.full-minus-org-header {
      min-height: calc(#{$min-height} - #{$org-header-height});
    }
  }
}

