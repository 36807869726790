@use 'libs/core/src/lib/styles/themes';

.assets-list {
  position: sticky;
  @include themes.themify {
    .assets-list-title {
      padding-top: themes.themed('spacing', S);
      padding-bottom: themes.themed('spacing', S);
      position: sticky;
      top: -1px;
      z-index: 66;
    }

    .pagination-controls {
      margin-top: themes.themed('spacing', S);
    }
  }
}
