.upload-form-container {
  .col-data-caret-down {
    margin-left: 18px;
  }

  .col-data-caret-right {
    margin-left: 18px;
  }

  .upload-form-icon-info {
    margin-left: 5px;
  }

  .organization-label,
  .brandfolder-label,
  .section-label,
  .collection-label {
    .bf-select {
      width: 100%;
    }
  }

  .remember-settings-label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
