@use './libs/core/src/lib/styles/themes';

.container-compact-list-item {
  @include themes.themify {
    display: flex;
    flex-direction: row;
    padding: themes.themed('spacing', XS);
    justify-content: space-between;

    &.delimiter {
      border-bottom: themes.themed('delimiters', 'size') solid themes.themed('delimiters', 'color');
      &:last-child {
        border-bottom: none;
      }
    }

    &.background-hover {
      > div {
        padding: 8px;
      }

      .selected {
        padding: 8px;
      }

      &:hover {
        background-color: themes.themed('background-hover', 'background-color');
        border-radius: themes.themed('background-hover', 'border-radius');
        transition: background-color 0.3s ease;
      }
    }
    .asset-count {
      padding-left: themes.themed('spacing', S);
      white-space: nowrap;
    }
  }
}
