// Same CSS grid used in the boulder repo for the asset card thumbnails
@mixin asset-card-grid {
  @supports (display: grid) {
    align-items: stretch;
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }
}

@mixin custom-grid($column-sizing, $align-items, $column-gap, $row-gap, $column-width) {
  @supports (display: grid) {
    align-items: $align-items;
    display: grid;
    grid-column-gap: $column-gap;
    grid-row-gap: $row-gap;
    grid-template-columns: repeat($column-sizing, minmax($column-width, 1fr));
  }
}