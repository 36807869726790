@use 'libs/core/src/lib/styles/components/icons';

.icon-add {
  @include icons.icon-base-theme-props();
  background: url('assets/add.svg') center no-repeat;
}

.icon-add-blue {
  @include icons.icon-base-theme-props();
  background: url('assets/add-blue.svg') center no-repeat;
}

.filter-disabled {
  filter: brightness(0) saturate(100%) invert(90%) sepia(2%) saturate(12%) hue-rotate(314deg) brightness(98%) contrast(79%);
}
