@use 'libs/core/src/lib/styles/themes';

.org-name {
  font-size: 13px !important;
  line-height: 23px !important;
  margin-right: 10px;
}

.organizations-list {
  @include themes.themify {
    .org-list-item {
      border-top: themes.themed('delimiters', 'size') solid themes.themed('delimiters', 'color');
    }

    .org-list-item:last-child {
      border-bottom: themes.themed('delimiters', 'size') solid themes.themed('delimiters', 'color');
    }
  }
}
