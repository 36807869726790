.thumbnail-overlay {
  background-color: transparent !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  opacity: 0;

  &.dark-background {
    background: rgba(82, 95, 127, 0.95) !important;
    /* transition effect. not necessary */
    transition: opacity 500ms, visibility 500ms;
  }

  .overlay-top {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px;

    p {
      color: #ffffff;
      font-size: 14px;
      margin: 0;
    }
  }

  .overlay-center {
    font-size: 14px;
    color: #ffffff;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    a,
    .actions-overlay-button {
      border-radius: 4px 4px 4px 4px;
      padding: 4px 12px;
      margin-bottom: 5px;
      display: block;
      z-index: 5;
      &:hover {
        cursor: pointer;
      }
    }

    p {
      margin-bottom: 5px;
      display: block;
      color: #ffffff;
    }
  }
}
