@use 'libs/core/src/lib/styles/themes';

.attachment-actions-popover-btn {
  width: 100%;

  .loading {
    border: 1px solid;
    color: #3576ba;
  }

  .label-container {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
  }

  .smar-spinner {
    margin-right: 4px;
  }
}
