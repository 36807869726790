@use 'libs/core/src/lib/styles/themes' as *;

.layout-body {
  @include themify {
    padding: themed('spacing', S) themed('spacing', L) themed('spacing', L);
  }
}

.layout-title {
  @include themify {
    margin-bottom: themed('spacing', XS);
  }
}
