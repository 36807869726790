.drop-zone {
  align-items: center;
  background: #F1F5FA;
  border: 1px dashed #CECECE;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  padding: 28px 91px;
  position: relative;

  .drop-zone-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    white-space: nowrap;
  }

  .icon-container {
    bottom: -10px;
    left: 42px;
    position: absolute;
  }
}