@use 'libs/core/src/lib/styles/components/buttons' as *;

.btn-primary {
  @include btn-primary-theme-props();
}

.btn-secondary {
  @include btn-secondary-theme-props();
}

.btn-active-dark {
  @include btn-active-dark-theme-props();
}

.btn-disabled {
  @include btn-disabled-theme-props();

  &:hover {
    cursor: not-allowed;
  }
}

.btn-success {
  @include btn-success-theme-props();
}

.transparent {
  background-color: transparent !important;
}
