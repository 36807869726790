@use 'libs/core/src/lib/styles/themes';

.back-button {
  @include themes.themify {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: themes.themed('spacing', XS);

    .button-text {
      max-width: 200px;
    }
  }
}
