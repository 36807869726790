.logo-header {
  width: 100%;
  height: 165px;
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;

  .logo {
    width: 200px;
  }
}
