#asset-details-container,
#section-upload-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  overflow-y: auto;
}
