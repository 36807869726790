:root {
  --primary: #4462c9;
  --secondary: #1d3163;
  --light-gray: #f8fafc;
  --gray: #e6e6e6;
  --border-gray: #cecece;
  --title: #333;
}

.auth {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;

  &__content {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 47px;

    &__section {
      width: 100%;
      margin-bottom: 45px;
    }

    .api-key-input-container {
      margin-bottom: 19px;
    }
  }

  &__get-started {
    width: 256px;
    text-align: center;
  }

  &__learn-more {
    margin-top: 2px;
    margin-bottom: 33px;
    text-align: center;
  }

  .btn {
    width: 100%;
  }

  .title {
    display: block;
    font-size: 28px;
    line-height: 33px;
    font-weight: 500;
    margin-bottom: 9px;
  }
}
