@use 'libs/core/src/lib/styles/themes';

@mixin drag-border {
  @include themes.themify {
    border: 2px dashed themes.themed('colors', light-border);
  }
}

.attachment-drag-and-drop-container {
  border: 2px solid transparent;

  &:hover {
    cursor: grab;
    @include drag-border;
  }

  &.dragging {
    cursor: grabbing;
    opacity: 0.4;
    @include drag-border;
  }
}
