@use './libs/core/src/lib/styles/themes';

.upload-to-brandfolder-container {
    min-width: 334px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}

.icon-upload-asset-success-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-to-brandfolder-title {
    @include themes.themify {
        text-align: center;
        font-size: themes.themed('header-1', 'font-size');
        font-weight: themes.themed('font-bold', 'font-weight');
        padding-bottom: themes.themed('spacing', M);
    }
}

.upload-to-brandfolder-message-container {
    line-height: 0px;

    @include themes.themify {
        padding-bottom: themes.themed('spacing', M);
    }
}

.upload-to-brandfolder-message {
    display: inline;

    @include themes.themify {
        font-size: themes.themed('base-typography', 'font-size');
        line-height: themes.themed('header-3', 'line-height');
    }
}

.upload-to-brandfolder-message-bold {
    @include themes.themify {
        font-weight: themes.themed('font-bold', 'font-weight');
    }
}

.upload-to-brandfolder-share-link-label {
    @include themes.themify {
        font-size: themes.themed('base-typography', 'font-size');
        line-height: themes.themed('header-3', 'line-height');
        padding-bottom: themes.themed('spacing', XS);
        color: themes.themed('colors', 'text-dark');
        font-weight: themes.themed('font-medium', 'font-weight');
    }
}

.upload-to-brandfolder-share-link-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include themes.themify {
        padding: themes.themed('spacing', XS);
        margin-bottom: themes.themed('spacing', XXL);
        border-radius: themes.themed('background-hover', 'border-radius');
        border: 2px solid themes.themed('colors', 'light-border');
    }
}

.upload-to-brandfolder-share-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include themes.themify {
        font-weight: themes.themed('font-semi-bold', 'font-weight');
        color: themes.themed('colors', 'text-semi-dark');
    }
}

.upload-to-brandfolder-share-link-copy {
    cursor: pointer;

    @include themes.themify {
        font-weight: themes.themed('font-semi-bold', 'font-weight');
        color: themes.themed('colors', 'cta-link');
    }
}

.upload-to-brandfolder-divider {
    margin-left: -30px;
    width: calc(100% + 60px);

    @include themes.themify {
        border-top: 2px solid themes.themed('colors', 'light-border');
    }
}

.upload-to-brandfolder-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;

    @include themes.themify {
        margin: themes.themed('spacing', XL) 0;
        border-radius: themes.themed('background-hover', 'border-radius');
    }
}

.open-in-brandfolder-button {
    @include themes.themify {
        margin-left: themes.themed('spacing', S);
    }
}
