@use 'libs/core/src/lib/styles/themes';

.section-content {
  @include themes.themify {
    padding-right: themes.themed('spacing', L);
    padding-left: themes.themed('spacing', L);
  }
}

.section-header {
  @include themes.themify {
    padding-right: themes.themed('spacing', L);
    padding-left: themes.themed('spacing', L);
  }
}
