@use '../../../../../core/src/lib/styles/components/loader' as *;

.loader {
  @include loader-theme-props();

  &-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 50px 0;
    width: 100%;
  }
}
