@use 'libs/core/src/lib/styles/themes' as *;

.asset-details-page-header {
  @include themify {
    padding-bottom: themed('spacing', S);

    .back-link-arrow {
      .icon-arrow-caret-left {
        filter: themed('colors', 'cta-link-filter');
      }
    }
  }
}
