.sq-20 {
  min-height: 20px;
  min-width: 20px;
}

.thumbnail-label-container {
  padding: 4px 4px 4px 0;
}

.attachment-checkbox {
  margin-right: 8px;
  margin-top: 4px;
  margin-left: 5px;
  &.disabled {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
  }
}

.attachment-name {
  padding-top: 3px;
}

.attachment-thumbnail {
  &.with-padding {
    padding: 15px;
  }
}
