@use 'libs/core/src/lib/styles/themes' as *;

.bf-thumbnail {
  @include themify {
    margin-bottom: themed('spacing', 'S');
  }

  &.selected {
    @include themify() {
      border: 2px solid themed('colors', 'primary');
      border-radius: themed('border', 'rounded');
    }
  }
}

.thumbnail-content {
  position: relative;
  > img {
    max-height: 100%;
    max-width: 100%;
  }
}
