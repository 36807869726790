@use 'libs/core/src/lib/styles/themes';

.sorting-selectors {
  border-radius: 4px;
  max-width: 358px;
  overflow: hidden;
  padding: 10px;
  position: absolute;
  right: 26px;
  top: 58px;
  transition: right 0.3s ease-in-out;
  z-index: 1;
  background-color: white;

  &.open {
    right: 0;
  }

  > .option {
    padding: 8px 15px;
    font-size: 14px;

    &:hover {
      @include themes.themify() {
        background-color: themes.themed('select', 'background-hover');
        border-radius: themes.themed('select', 'border-radius-hover');
        transition: background-color 0.3s ease;
      }
    }
  }

  @include themes.themify() {
    box-shadow: themes.themed('colors', 'box-shadow');
  }
}
