@use 'libs/core/src/lib/styles/themes';

.show-page-nav {
  position: relative;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  @include themes.themify {
    padding-top: themes.themed('spacing', 'S');
    padding-bottom: themes.themed('spacing', 'S');
    border-bottom: solid themes.themed('delimiters', 'size') themes.themed('delimiters', 'color');

    &__back-link {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100%;
      font-size: 13px;
      line-height: 18px;

      >span {
        justify-content: flex-start;
        padding: 0;
      }

      &--name {
        /* same height as icon to align org name vertically with the icon */
        height: 20px;
        margin-left: 10px;
      }
    }

    .back-link-arrow {
      .icon-arrow-caret-left {
        filter: themes.themed('colors', 'cta-link-filter');
      }
    }
  }
}
