@use 'libs/core/src/lib/styles/themes' as *;

.show-page-controls-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: sticky;
  z-index: 1;
  @include themify {
    padding-top: themed('spacing', 'M');
    padding-bottom: themed('spacing', 'S');
  }
}

.show-page-controls-top {
  @include themify() {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding-bottom: themed('spacing', 'S');

    .selected-container-title {
      font-size: themed('show-page', 'container-title-size');
    }
  }
}

.show-page-controls-selector-list-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.show-page-controls-selector-list-content {
  background-color: white;
  border-radius: 4px;
  padding: 8px;
  width: 199px;
  z-index: 100;
  max-height: 400px;
  overflow: auto;

  .pins-filters-list-item,
  .section-list-item {
    padding: 8px;
    border-radius: 4px;

    &:hover {
      @include themify() {
        background-color: themed('background-hover', 'background-color');
        border-radius: themed('background-hover', 'border-radius');
        transition: background-color 0.3s ease;
      }
    }
  }

  .selected {
    padding: 6px;

    @include themify() {
      background-color: themed('background-hover', 'background-color');
      border-radius: themed('background-hover', 'border-radius');
    }

    @include themify() {
      font-family: themed('font-semi-bold', 'font-family');
      font-style: themed('font-semi-bold', 'font-style');
      font-weight: themed('font-semi-bold', 'font-weight');
    }
  }
}

.show-page-controls-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  .filter-button-wrapper,
  .pins-button-wrapper {
    margin-right: 8px;
  }

  .filters-btn {
    min-width: 40px;
    .btn-content {
      gap: 6px !important;
    }
  }

  .pins-button-content {
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;

    > span {
      margin-right: 2px;
    }
  }

  .show-page-controls-search-input {
    width: 100%;
  }
}

.show-page-controls-bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include themify {
    margin-top: themed('spacing', 'S');
  }
}

.toggle-switch-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  .toggle-text {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
  }
}

.label-path-list {
  margin: 26px 0;
  font-size: 13px;
  display: flex;
  .label-path-list-icon {
    justify-content: center;
    @include themify() {
      margin-right: themed('spacing', 'S');
    }
  }

  .label-list-separator,
  .label-path-node,
  .label-path-list-icon {
    @include themify() {
      margin-right: themed('spacing', 'XS');
    }
    display: inline-block;
  }

  .label-path-node.last-node {
    font-weight: bold;
  }

  .label-list-clear-btn {
    padding-top: 2px;
    color: #3576ba;
  }
}
