.multi-select-overlay {
  $multi-select-overlay-height: 105px;

  display: flex;
  flex-direction: row;
  position: fixed;
  justify-content: center;
  background-color: white;
  height: $multi-select-overlay-height;
  width: 100vw;
  left: 0;
  bottom: -$multi-select-overlay-height;
  padding: 0 35px;
  z-index: 200;
  transition: bottom 0.3s ease-in-out;
  border-top: 2px solid #E0E0E0;

  &__link {
    margin: 0 8px;
  }

  &.open {
    bottom: 0;
    transition: bottom 0.3s ease-in-out;
  }
}