@use 'libs/core/src/lib/styles/components/icons';

.icon-caret-down-primary {
  @include icons.icon-caret-down-primary-theme-props;
}

.icon-caret-down-secondary {
  @include icons.icon-caret-down-secondary-theme-props;
}

.icon-caret-down-white {
  @include icons.icon-caret-down-white-theme-props;
}
