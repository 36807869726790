@use 'libs/core/src/lib/styles/themes' as *;
@use './libs/core/src/lib/styles/utilities/css-grids' as *;
@use './libs/core/src/lib/styles/utilities/media-queries' as *;

.responsive-thumbnail-list {
  @include themify {
    @include custom-grid(auto-fill, stretch, themed('spacing', 'L'), themed('spacing', 'M'), 175px);

    // Override fixed thumbnail width on smaller screen
    .attachment-thumbnail {
      margin-bottom: 0;
      width: 100% !important;
    }
  }
}
