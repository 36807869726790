@use 'libs/core/src/lib/styles/components/icons';

.icon-caret-left-primary {
  @include icons.icon-caret-right-primary-theme-props;
  transform: rotate(180deg);
}
.icon-caret-left-secondary {
  @include icons.icon-caret-right-secondary-theme-props;
  transform: rotate(180deg);
}
