.asset-details-page-header {
  &__name {
    /* same height as icon to align brandfolder name vertically with the icon */
    height: 20px;
    margin-left: 8px;
  }
}

.asset-details-name {
  margin-bottom: 10px;
}
