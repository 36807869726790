@use 'libs/core/src/lib/styles/themes';

.page-container {
  @include themes.themify {
    padding: 0 themes.themed('spacing', 'L');
    background-color: white;
    z-index: 0;

    .page-header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding-top: themes.themed('spacing', 'M');
      padding-bottom: themes.themed('spacing', 'XS');
    }

    .page-nav {
      padding-top: themes.themed('spacing', 'S');
      padding-bottom: themes.themed('spacing', 'S');
      border-bottom: solid themes.themed('delimiters', 'size') themes.themed('delimiters', 'color');
    }

    .page-content {
      &.page-content-full-width {
        margin: 0 - (themes.themed('spacing', 'L'));
      }
    }

    .page-scroll-container {
      overflow: auto;
      margin-left: -(themes.themed('spacing', 'L'));
      margin-right: -(themes.themed('spacing', 'L'));
      padding-left: themes.themed('spacing', 'L');
      padding-right: themes.themed('spacing', 'L');
    }

    .page-section {
      position: relative;
      border-top: themes.themed('delimiters', 'size') solid themes.themed('delimiters', 'color');

      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: themes.themed('delimiters', 'size') solid
          themes.themed('delimiters', 'color');
      }

      padding: themes.themed('spacing', 'L') 0;

      &:not(.expanded) {
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .page-section-title,
      .page-subsection-title {
        width: 100%;
        margin-bottom: themes.themed('spacing', 'M');
      }

      .page-section-title {
        display: flex;
        align-items: center;

        &:not(.expanded) {
          margin-bottom: 0;
        }

        .expand-icon {
          height: 18px;
          margin-right: themes.themed('spacing', 'S');
        }
      }

      .page-section-body {
        width: 100%;
      }

      .page-subsection {
        margin-bottom: themes.themed('spacing', 'XL');

        &:last-child {
          margin-bottom: 0;
        }
      }

      .toggle-expand {
        position: absolute;
        top: 0;
        left: 0;
        height: 70px;
        width: 100%;
        cursor: pointer;
        z-index: 200;
      }
    }

    .page-section-header {
      margin-bottom: themes.themed('spacing', 'M');
    }

    .page-group {
      &.vertical {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .page-row {
        &.vertical {
          display: flex;
          flex-direction: column;
          gap: themes.themed('spacing', 'XS');
        }
      }
    }

    .page-actions {
      display: flex;
      position: sticky;
      bottom: 0;
      z-index: 300;
      justify-content: space-between;
      align-items: center;
      border-top: 2px solid themes.themed('delimiters', 'color');
      border-bottom: 2px solid themes.themed('delimiters', 'color');
      padding-left: themes.themed('spacing', 'XL');
      padding-right: themes.themed('spacing', 'XL');
      height: 90px;
    }
  }
}
