@use 'libs/core/src/lib/styles/themes' as *;

.bf-input {
  padding: 10px;
  &.s {
    height: 40px;
  }

  &.m {
    height: 50px;
  }
}
