@use 'libs/core/src/lib/styles/themes';

.view-options-dropdown {
  border-radius: 4px;
  min-width: 300px;

  @include themes.themify() {
    background-color: themes.themed('colors', 'white');
  }
}

.view-options-title {
  padding: 10px 18px;
  @include themes.themify() {
    color: themes.themed('header-6', 'color');
    font-family: themes.themed('header-6', 'font-family');
    font-size: themes.themed('header-6', 'font-size');
    font-weight: themes.themed('header-6', 'font-weight');
    line-height: themes.themed('header-6', 'line-height');
  }
}

.view-options-item {
  padding-top: 8px;
  padding-bottom: 8px;
}

.shadow-border {
  @include themes.themify() {
    box-shadow: themes.themed('colors', 'box-shadow');
  }
}

.selected-sort-option {
  @include themes.themify() {
    font-size: themes.themed('base-typography', 'font-size');
  }

  background-position: 91%;
  border-style: solid;
  border-width: 1px;
  padding: 11px 40px 11px 12px;
}
