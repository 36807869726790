@use 'libs/core/src/lib/styles/themes' as *;

.popover-menu {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: none;
  overflow: hidden;
  padding: 8px;
  z-index: 999;

  @include themify() {
    background-color: themed('colors', 'white');
    border-radius: themed('border', 'rounded');
    box-shadow: themed('colors', 'box-shadow');
    color: themed('colors', 'text-dark');
  }

  .popover-menu-item {
    @include themify {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px;
      width: 100%;

      &:hover {
        background-color: themed('background-hover', 'background-color');
        border-radius: themed('background-hover', 'border-radius');
        transition: background-color 0.3s ease;
      }

      &:disabled {
        color: themed('button-disabled', 'color-disabled');
        span {
          opacity: 0.4;
        }
      }
    }
  }
}
