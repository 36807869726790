@use '../../../../core/src/lib/styles/components/badges' as *;

.pill-badge-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  height: 100%;
  width: 100%;
}

.badge-primary {
  @include badge-primary-theme-props();
}

.badge-secondary {
  @include badge-secondary-theme-props();
}
