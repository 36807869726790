@use 'libs/core/src/lib/styles/themes' as *;

.more-options-menu-panel-container {
  color: #444444;
  z-index: 200;
  border-radius: 4px;
}

.asset-attachment-name {
  word-break: break-word;
}

.attachment-options-button {

  &.transparent-background {
    background-color: transparent;
  }

  &.open {
    @include themify() {
      background-color: themed('background-hover', 'background-color') !important;
    }
  }
}
