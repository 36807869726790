@use 'libs/core/src/lib/styles/components/icons';

.icon-arrow-right {
  @include icons.icon-arrow-right-theme-props;
}

.icon-arrow-right-white {
  @include icons.icon-arrow-right-white-theme-props;
}

.icon-arrow-left {
  @include icons.icon-arrow-right-theme-props;
  transform: rotate(180deg);
}

.icon-arrow-left-white {
  @include icons.icon-arrow-right-white-theme-props;
  transform: rotate(180deg);
}

.icon-arrow-caret-left {
  @include icons.icon-arrow-caret-left-theme-props;
  transform: rotate(180deg);
}